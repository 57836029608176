<template>
  <div class="">
    <p class="mb-3">
      Seleziona la tipologia di segnalazione che si sta effettuando.
    </p>
    <p class="mb-4">
      In seguito, seleziona la voce che corrisponde alla tua situazione.
    </p>
    <h5 class="heading--dark">Seleziona la tipologia di segnalazione:</h5>
    <b-input-group class="mt-2">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <v-select
            disabled
            id="feedbackType"
            label="value"
            placeholder="Selezionare una voce"
            v-model="reportDetail.feedbackType"
            :options="feedbackTypes"
            :reduce="item => item.key"
          >
          </v-select>
        </div>
      </div>
    </b-input-group>
    <b-input-group class="mt-2" v-if="needAmount">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <b-form-group label="Importo">
            <vue-numeric
              title="Importo"
              currency="€"
              v-bind:min="0"
              v-bind:max="9999999"
              separator="."
              v-model="reportDetail.reportAmount.amount"
              v-bind:minus="false"
              class="form-control"
              :state="invalidAmount"
            ></vue-numeric>
          </b-form-group>
        </div>
      </div>
    </b-input-group>
    <b-input-group class="my-3" v-if="isQuestionLoaded">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <select-key-value-with-icons
            :options="feedbackQuestions"
            v-model="reportDetail.feedbackQuestion"
            v-on:option:selected="setResponse"
            placeholder="Selezionare una voce"
            title="Ricerca Sezione"
            id="feedbackQuestion"
          />
        </div>
      </div>
    </b-input-group>
    <h5 class="heading--dark mb-2 mt-3">Descrizione segnalazione:</h5>
    <b-form-textarea v-if="needDescription"
      id="notes"
      v-model="reportDetail.note"
      placeholder=""
      rows="6"
      max-rows="6"
    ></b-form-textarea>
    <b-input-group class="my-3" v-if="isDetailLoaded && !needDescription">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <v-select
            id="feedbackDetail"
            label="value"
            placeholder="Selezionare una voce"
            v-model="reportDetail.feedbackDetail"
            :options="feedbackDetails"
            :reduce="item => item.key"
          >
          </v-select>
        </div>
      </div>
    </b-input-group>
  </div>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';

const SelectKeyValueWithIcons = () => import('@/components/helpers/SelectKeyValueWithIcons.vue');

export default {
  name: 'ReportTypeSelection',
  props: {
    needDescription: Boolean,
    reportDetail: {
      feedbackType: String,
      feedbackQuestion: String,
      feedbackDetail: String,
      note: String,
      response: String,
      reportAmount: {
        currency: String,
        amount: Number,
      },
    },
  },
  components: { SelectKeyValueWithIcons },
  // created() {
  //   if (!this.$store.getters['tableUtils/hasUserReportType'] && !this.$store.getters['tableUtils/isLoadingUserReportType']) {
  //     this.$store.dispatch('tableUtils/loadUserReportType', 'it').then(() => {
  //       console.log('loaded UserReportType');
  //     });
  //   }
  // },
  mounted() {
    this.loadQuestions();
  },
  computed: {
    feedbackTypes() {
      return this.$store.getters['tableUtils/getUserReportType'];
    },
    isQuestionLoaded() {
      // console.log('isQuestionLoaded 1', this.reportDetail.feedbackType);
      if (isNotEmpty(this.reportDetail.feedbackType)) {
        // console.log('isQuestionLoaded 2', this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackType));
        if (this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackType)) {
          return false;
        }
        // console.log('isQuestionLoaded', this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackType));
        return this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackType);
      }
      return false;
    },
    isDetailLoaded() {
      // console.log('isQuestionLoaded 1', this.reportDetail.feedbackType);
      if (isNotEmpty(this.reportDetail.feedbackQuestion)) {
        // console.log('isQuestionLoaded 2', this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackType));
        if (this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackQuestion)) {
          return false;
        }
        // console.log('isQuestionLoaded', this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackType));
        return true;
      }
      return false;
    },
    feedbackQuestions() {
      if (isNotEmpty(this.reportDetail.feedbackType)) {
        return this.$store.getters['tableUtils/getTableList'](this.reportDetail.feedbackType) || [];
      }
      return [];
    },
    feedbackDetails() {
      if (isNotEmpty(this.reportDetail.feedbackQuestion)) {
        return this.$store.getters['tableUtils/getTableList'](this.reportDetail.feedbackQuestion) || [];
      }
      return [];
    },
    needAmount() {
      const type = this.feedbackTypes.find((item) => item.key === this.reportDetail.feedbackType);
      return type?.additionalInfos?.hasAmount;
    },
  },
  methods: {
    loadQuestions() {
      console.log('loadQuestions', this.reportDetail.feedbackType);
      if (isPresent(this.reportDetail.feedbackType)) {
        this.reportDetail.feedbackQuestion = null;
        if (!this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackType)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackType)) {
            this.$store.dispatch('tableUtils/loadTableList', {
              tableName: this.reportDetail.feedbackType,
              language: 'it',
            });
          }
        }
      }
    },
    loadDescriptions() {
      console.log('loadDescriptions', this.reportDetail.feedbackQuestion);
      if (isPresent(this.reportDetail.feedbackQuestion)) {
        this.reportDetail.feedbackDetail = null;
        if (!this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackQuestion)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackQuestion)) {
            this.$store.dispatch('tableUtils/loadTableList', {
              tableName: this.reportDetail.feedbackQuestion,
              language: 'it',
            });
          }
        }
      }
    },
    setResponse() {
      console.log('setResponse', this.reportDetail.feedbackQuestion);
      if (isPresent(this.reportDetail.feedbackQuestion)) {
        const question = this.feedbackQuestions.filter((item) => item.key === this.reportDetail.feedbackQuestion);
        if (question && question.length > 0) {
          this.loadDescriptions();
          const { additionalInfos } = question[0];
          const responses = Object.keys(additionalInfos);
          if (responses && responses.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.reportDetail.response = responses[0];
          }
        }
      } else {
        this.reportDetail.response = null;
      }
    },
    invalidAmount() {
      return this.reportDetail.reportAmount.amount <= 0;
    },

  },
};
</script>

<style scoped>

</style>
